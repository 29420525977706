import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import './../App.css';
import miImagen from '../images/logo.png';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    marginVertical: 1,
    fontFamily: 'Oswald'
  },
  subtitleData: {
    fontSize: 10,
    margin: 12,
    marginVertical: 1,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    //marginVertical: 1,
    //marginHorizontal: 230,
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 20
  },
  header: {
    fontSize: 13,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    borderTopWidth: 120,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  tableRow: {
    flexDirection: 'row',
    fontSize: 10,
    borderBottomWidth: 1,
    borderColor: '#0000',
    alignItems: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
  },
  tableCol: {
    padding: 5,
    borderRightWidth: 1,
    borderColor: '#000',
    fontSize: 10,
    flexGrow: 1,
    width: 350,
  },
  tableCold: {
    padding: 5,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderColor: '#000',
    fontSize: 10,
    flexGrow: 1,
    width: 0,
  },
  tableColds: {
    padding: 5,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderColor: '#000',
    fontSize: 10,
    flexGrow: 5.1,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontSize: 10,
    fontWeight: 'bold',
    width: 1,
  },
  tableMargen: {
    margin: 12,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 50,
    right: 50,
    textAlign: 'center',
    fontSize: 10,
    color: 'grey',
  },
  page: {
    padding: 30,
  },
});

const DocuPDF = ({ pauta }) => {
  return (
    <Document>
      <Page size="letter" style={styles.page}>
        <Image
          style={styles.image}
          src={miImagen} />
        <Text style={styles.title}>Estado de cumplimiento de frentes</Text>
        <Text style={styles.title}><img src={pauta.firma_electrico} alt="Logo" /></Text>
        <Text style={styles.subtitleData}>
          MINA:                         {pauta.mina_id ? pauta.mina_id : "..."}
        </Text>

        <Text style={styles.subtitleData}>
          UBICACIÓN:               {pauta.ubicacion ? pauta.ubicacion : "..."}
        </Text>

        <Text style={styles.subtitleData}>
          SUPERVISOR:             {pauta.display_user ? pauta.display_user : "..."}
        </Text>

        <Text style={styles.subtitleData}>
          FECHA:                       {pauta.fecha.toDate().toLocaleString()}
        </Text>

        <View style={styles.tableMargen}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableColds, styles.tableHeader]}>Pregunta   </Text>
            <Text style={[styles.tableCold, styles.tableHeader]}>Respuesta</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>1. Cuenta con señal radial a la frente</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_uno ? pauta.estandar_uno : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>2. Acuñadura y mallas descargadas</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_dos ? pauta.estandar_dos : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>3. Se realiza fortificación según recomendación geomecánica.</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_tres ? pauta.estandar_tres : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>4. Disponibilidad de Acuñador largo y corto</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_cuatro ? pauta.estandar_cuatro : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>5. Concentración de CO menor a 40 PPM</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_cinco ? pauta.estandar_cinco : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>6. Concentración de N02 menor a 2,4 PPM</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_seis ? pauta.estandar_seis : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>7. Concentración de O2 mayor a 19,5% </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_siete ? pauta.estandar_siete : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>8. Caudal de aire por HP 2,83 m3/min. Más 3 m3/min Personas.</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_ocho ? pauta.estandar_ocho : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>9. Uso de loro metálico y/o cenefa para restringir acceso</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_nueve ? pauta.estandar_nueve : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>10. La postura cuenta con Isométrico de vías de evacuación y refugio más cercano</Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_diez ? pauta.estandar_diez : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>11. Se cuenta con tablero TEJ a 75 Mtrs                                                               </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_once ? pauta.estandar_once : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>12. Estado de pisos, fragmentos de roca y agua                                                    </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_doce ? pauta.estandar_doce : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>13. Disponen de baño químico a menos de 150 m                                                </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_trece ? pauta.estandar_trece : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>14. Disponen de Agua potable                                                                               </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_catorce ? pauta.estandar_catorce : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>15. Temperatura menor 30°C *                                                                              </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_quince ? pauta.estandar_quince : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>16. Buen estado de Manga de ventilación a 30 metros de la frente                     </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_dieciseis ? pauta.estandar_dieciseis : "..."}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>17. Cuenta con cajones para restos de explosivos en el nivel                             </Text>
            <Text style={styles.tableCold}>
              {pauta.estandar_diecisiete ? pauta.estandar_diecisiete : "..."}
            </Text>
          </View>
        </View>
        <Text style={styles.text}>Observación :
          {pauta.observacion ? pauta.observacion : "..."}
        </Text>
        <Text style={styles.footer}>
          <Text>  Codigo reporte : {pauta.id ? pauta.id : "..."}
          </Text>
        </Text>
      </Page>
    </Document>
  );
};

export default DocuPDF;