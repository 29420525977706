import React, { useState } from 'react'
import '../App.css';

import logo from '../images/logo_sm.png'

import appFirebase from '../credenciales'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
const auth = getAuth(appFirebase)

const Login = () => {

  const [registro] = useState(false)

  const handlerSubmit = async (e) => {
    e.preventDefault()
    const correo = e.target.email.value;
    const password = e.target.password.value;

    if (registro) {
      await createUserWithEmailAndPassword(auth, correo, password)
    }
    else {
      await signInWithEmailAndPassword(auth, correo, password)
    }
  }
  return (
    <div className="wrapper">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="text-center mt-4 name">
        Geovita SSOMA
      </div>
      <form className="p-3 mt-3" onSubmit={handlerSubmit}>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-user" />
          <input type="text" id='email' required placeholder="Email" />
        </div>
        <div className="form-field d-flex align-items-center">
          <span className="fas fa-key" />
          <input type="password" id='password' required placeholder="Password" />
        </div>
        <button className='btn mt-3' type='submit'>
          Login      </button>
      </form>
      <div className="text-center fs-6">
      </div>
    </div>
  )
}

export default Login