import React, { useEffect, useState } from 'react'
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocuPDF from "./DocuPDF";
import appFirebase from '../credenciales'
import { getAuth, signOut } from 'firebase/auth'
import { getFirestore, getDocs, collection, orderBy,query } from 'firebase/firestore'
import ExcelJS from 'exceljs';
import ReactPaginate from 'react-paginate';


const auth = getAuth(appFirebase)
const db = getFirestore(appFirebase)

const Home = ({ correoUsuario }) => {
  //variables de estado
  const [lista, setLista] = useState([])
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5; // Puedes ajustar este número según tus necesidades
  
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  
  const offset = pageNumber * itemsPerPage;
const currentPageItems = lista.slice(offset, offset + itemsPerPage);


  useEffect(() => {
      const getLista = async () => {
          try {
              const querySnapshot = await getDocs(
                  query(collection(db, 'estatu_cumplimiento_frente'), orderBy('fecha', 'desc'))
              )
              const docs = []
              querySnapshot.forEach((doc) => {
                  docs.push({ ...doc.data(), id: doc.id })
              })
              setLista(docs)
          } catch (error) {
              console.log(error)
          }
      }
      getLista()
  }, [])
  

  
  
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Datos');
  
    // Define las columnas del archivo Excel
    sheet.columns = [
      { header: 'Mina', key: 'mina_id', width: 10 },
      { header: 'Ubicación', key: 'ubicacion' , width: 10 },
      { header: 'Supervisor', key: 'display_user' },
      { header: '1. Cuenta con señal radial a la frente	', key: 'estandar_uno' , width: 10 },
      { header: '2. Acuñadura y mallas descargadas	', key: 'estandar_dos' , width: 10 },
      { header: '3. Se realiza fortificación según recomendación geomecánica.	', key: 'estandar_tres' , width: 10 },
      { header: '4. Disponibilidad de Acuñador largo y corto', key: 'estandar_cuatro' , width: 10 },
      { header: '5. Concentración de CO menor a 40 PPM', key: 'estandar_cinco' , width: 10 },
      { header: '6. Concentración de N02 menor a 2,4 PPM', key: 'estandar_seis' , width: 10 },
      { header: '7. Concentración de O2 mayor a 19,5% ', key: 'estandar_siete' , width: 10 },
      { header: '8. Caudal de aire por HP 2,83 m3/min. Más 3 m3/min Personas.', key: 'estandar_ocho' , width: 10 },
      { header: '9. Uso de loro metálico y/o cenefa para restringir acceso', key: 'estandar_nueve' , width: 10 },
      { header: '10. La postura cuenta con Isométrico de vías de evacuación y refugio más cercano', key: 'estandar_diez' , width: 10 },
      { header: '11. Se cuenta con tablero TEJ a 75 Mtrs', key: 'estandar_once' , width: 10 },
      { header: '12. Estado de pisos, fragmentos de roca y agua', key: 'estandar_doce' , width: 10 },
      { header: '13. Disponen de baño químico a menos de 150 m', key: 'estandar_trece' , width: 10 },
      { header: '14. Disponen de Agua potable', key: 'estandar_catorce' , width: 10 },
      { header: '15. Temperatura menor 30°C *	', key: 'estandar_quince' , width: 10 },
      { header: '16. Buen estado de Manga de ventilación a 30 metros de la frente', key: 'estandar_dieciseis' , width: 10 },
      { header: '17. Cuenta con cajones para restos de explosivos en el nivel', key: 'estandar_diecisiete' , width: 10 },
      { header: 'Fecha', key: 'fecha' },
      { header: 'Observación', key: 'observacion', width: 30 },
    ];
  
      // Estilo de la cabecera
  sheet.getRow(1).font = { bold: true };
  sheet.getRow(1).alignment = { horizontal: 'left' };
  sheet.getRow(1).height = 15;

    // Agrega los datos de la tabla al archivo Excel
    lista.forEach((item) => {
      sheet.addRow({
        mina_id: item.mina_id,
        ubicacion: item.ubicacion,
        display_user: item.display_user,
        fecha: item.fecha.toDate().toLocaleString(),
        observacion: item.observacion,
        estandar_uno: item.estandar_uno,
        estandar_dos: item.estandar_dos,
        estandar_tres: item.estandar_tres,
        estandar_cuatro: item.estandar_cuatro,
        estandar_cinco: item.estandar_cinco,
        estandar_seis: item.estandar_seis,
        estandar_siete: item.estandar_siete,
        estandar_ocho: item.estandar_ocho,
        estandar_nueve: item.estandar_nueve,
        estandar_diez: item.estandar_diez,
        estandar_once: item.estandar_once,
        estandar_doce: item.estandar_doce,
        estandar_trece: item.estandar_trece,
        estandar_catorce: item.estandar_catorce,
        estandar_quince: item.estandar_quince,
        estandar_dieciseis: item.estandar_dieciseis,
        estandar_diecisiete: item.estandar_diecisiete,
      }).eachCell((cell) => {
        // Establece los estilos de las celdas
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
        cell.font = { size: 12, bold: false };
      });
    });
    
    // Descarga el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Reporte.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  


  return (
    <div className="container">
      <p>Bienvenido, <strong>{correoUsuario}</strong> haz iniciado sesion</p>

      <button className="btn btn-primary" onClick={() => signOut(auth)}>
        Cerrar session
      </button>
      <hr />

      <div className='row'>
        {/* esta seccion es del formulario */}

        {/* esta session sera la lista */}
        <div className='col-md-12'>
          <h2 className='text-center'>Registros cumplimiento frentes</h2>
          <div className="card">
            <div className="card-header">
            <button onClick={exportToExcel} className="btn btn-relief-secondary waves-effect waves-float waves-light">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
            <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
           </svg>
               <span>Exportar</span>
              </button>
            </div>
            <div className="card-body">
              <p className="card-text">
              </p>
              <div className="alert alert-info">
                <div className="alert-body">
                  <h4 className="text-warning">Listado cumplimiento</h4>
                  <p>
                  </p>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">Mina</th>
                    <th scope="col" className="text-nowrap">Ubicación</th>
                    <th scope="col" className="text-nowrap">Supervisor</th>
                    <th scope="col" className="text-nowrap">Fecha</th>
                    <th scope="col" className="text-nowrap">Observación</th>
                    <th scope="col" className="text-nowrap">Descargar</th>
                  </tr>
                </thead>
                <tbody>
              
                  
                    {currentPageItems.map(list => (
                      <tr className="table-active" key={list.id}>
                        <td>{list.mina_id}</td>
                        <td>{list.ubicacion}</td>
                        <td>{list.display_user}</td>
                        <td>{list.fecha.toDate().toLocaleString()}</td>
                        <td>{list.observacion }</td>
                        <td>
                          <PDFDownloadLink
                            document={<DocuPDF pauta={list} />}
                            fileName="cumplimiento.pdf">
                            <button type="button" class="btn btn-icon btn-icon rounded-circle btn-danger waves-effect waves-float waves-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                          </svg>                                        
                          </button>
                          </PDFDownloadLink>
                        </td>
                        
                      </tr>
                    ))
                  }
                </tbody>
              

              </table>
              {/* Sección de paginación */}

            </div>
            <ReactPaginate
            pageCount={Math.ceil(lista.length / itemsPerPage)}
            onPageChange={handlePageChange}
            containerClassName="pagination justify-content-end mb-0"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            disabledClassName="disabled"
          />
          </div>
        </div>
      </div>
    </div>
  )
}



export default Home